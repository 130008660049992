import {EitjeDropdown} from '../eitje_dropdown'
import {MenuLink} from './components'
import {useMenuMapping} from './hooks'
import {isSubItemActive} from './helpers'

const offset = [0, 20]

export const LeftMenu = () => {
	const taxonomy = useMenuMapping()

	return formattedMapping(taxonomy).map(item => {
		const hasItems = item.items?.length
		const items = item.items?.filter(i => !i.children)
		const isActive = isSubItemActive(items)
		const link = <MenuLink isActive={isActive} {...item} />

		if (!hasItems) return link

		return (
			<EitjeDropdown className="menu-item-dropdown" trigger="hover" elements={item.items} offset={offset} strategy="fixed">
				{link}
			</EitjeDropdown>
		)
	})
}

const formattedMapping = mapping => {
	return mapping.map(item => ({
		...item,
		items: item.items?.map(subItem => ({
			...subItem,
			element: <MenuLink {...subItem} />,
			children: subItem.children?.map(nestedItem => ({
				...nestedItem,
				element: <MenuLink {...nestedItem} />,
			})),
		})),
	}))
}
