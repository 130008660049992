import * as variables from '../styles/colors.module.less' // import as ES module is needed since Gatsby V3

const excludedProps = ['tooltip']

export const withColor = Component => props => {
	if (props.color) return <Component {...props} color={props.color} />

	const colorFromProps = Object.keys(props).find(prop => !excludedProps.includes(prop) && !!props[prop] && !!Colors[prop])
	const color = (colorFromProps && Colors[colorFromProps]) || undefined
	return <Component {...props} color={color} />
}

export const Colors = variables
