import {Colors} from '/src/helpers'
import {Layout} from '../layout'

const size = [8, 12]

export const DotsNavigation = ({activeIndex, onChange, elements, ...rest}) => {
	if (elements.length < 2) return null
	return (
		<Layout gap={[4, 8]} horizontal="center" className="dots-navigation" {...rest}>
			{elements.map((el, i) => {
				const inlineStyles = {
					backgroundColor: activeIndex === i && Colors.darkGrey,
					borderWidth: 2,
					borderColor: Colors.darkGrey,
				}
				return <Layout key={i} onClick={() => onChange(i)} border height={size} width={size} borderRadius="50%" style={inlineStyles} />
			})}
		</Layout>
	)
}
