import {ReactSVG} from 'react-svg'
import './styles.less'
import utils from '@eitje/utils'
import {getResp} from '../layout'

// IMPORTANT

// The <svg> tags of icons should have the following attribute for the icon to show:
// xmlns="http://www.w3.org/2000/svg"

export const Icon = ({name, initialFill, fill = initialFill, stroke, type = 'regular', size = 16, className, ...rest}) => {
	if (!name) return null
	size = getResp(size)

	if (typeof size === 'number') size = `${size}px`

	const classNames = utils.makeCns(
		utils.makeCnVariants('eitje-icon', !rest.onClick && 'no-pointer'),
		className,
		utils.makeCnVariants('react-svg-wrapper', initialFill && 'initial-fill'),
	)

	// Prevents focus from shifting to the SVG icon when clicked inside a focused input.
	// This allows the onClick to respond immediately without requiring a second click.
	const handleMouseDown = e => e.preventDefault()

	return (
		<ReactSVG
			className={classNames}
			beforeInjection={svg => {
				const paths = svg.querySelectorAll('path,line,g')
				svg.setAttribute('width', size)
				svg.setAttribute('height', size)
				const shouldColor = svg.getAttribute('dontColor') !== 'true'
				if (shouldColor && (fill || stroke)) {
					paths?.forEach(path => path.setAttribute('style', `fill: ${fill}; stroke: ${stroke}`))
				}
				if (rest.onClick) {
					svg.style.cursor = 'pointer'
				}
			}}
			src={require(`../../images/icons/flaticon/${type}/${name}.svg`)?.default}
			wrapper="span"
			onMouseDown={handleMouseDown}
			style={{width: size, height: size}}
			{...rest}
		/>
	)
}
