import {Colors} from '/src/helpers'
import {Layout} from '../layout'

const DottedLine = () => {
	const inlineStyles = {
		borderBottom: `1px dotted ${Colors.mediumBorder}`, // The color is needed because of legacy css
	}

	return <Layout grow margin="12 40 - -" style={inlineStyles} />
}

export const InputLayout = ({children}) => {
	const [leftContent, rightContent] = children
	return (
		<Layout width="100%" horizontal="spaceBetween" height={48}>
			<Layout grow>
				{leftContent}
				<DottedLine />
			</Layout>
			{rightContent}
		</Layout>
	)
}
