import {Colors} from '/src/helpers'
import {Icon, Layout, Text, Banner} from '/src/components'
import {useWindowWidth} from '/src/contexts'
import {useLocaleUrl} from '/src/hooks'
import './styles.less'

export const ProductTiles = ({items = [], marginTop = 100, type}) => {
	const {mobile} = useWindowWidth()
	const childrenPerRow = mobile ? 2 : 3

	return (
		<>
			{type && <Banner type={type} marginTop={marginTop} />}
			<Layout marginTop={20} className="product-tiles" maxWidth={720} childrenPerRow={childrenPerRow} gap={12}>
				{items.map(ProductTile)}
			</Layout>
		</>
	)
}

const ProductTile = ({icon, title, subtitle, url: _url}) => {
	const {url} = useLocaleUrl(_url)

	return (
		<Layout
			className="product-tile"
			padding="- 12"
			horizontal="center"
			vertical="center"
			direction="vertical"
			parentGap={12}
			height={148}
			borderRadius={4}
			border
			initialBackground={Colors.white}
			to={url}
		>
			<Layout gap={10} direction="vertical" horizontal="center" width="100%">
				<Icon name={icon} fill={Colors.mediumBlue} size={18} />
				<Text large weight="semiBold" t={title} />
			</Layout>
			<Text darkGrey center t={subtitle} fontSize={14} />
		</Layout>
	)
}
