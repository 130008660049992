import React, {useState, useEffect} from 'react'
import {DotLottieReact} from '@lottiefiles/dotlottie-react'
import {animations} from './animations'
import {Layout} from '../layout'

export const AnimatedIcon = React.memo(({animation, size, height, width, animate, play = 'hover'}) => {
	const [isClient, setIsClient] = useState(false)
	const [dotLottieRef, setDotLottieRef] = useState(null)

	useEffect(() => {
		setIsClient(true)
	}, [])

	useEffect(() => {
		if (!dotLottieRef || animate == null) return
		// only play or pause the animation if the animate prop is true or false (instead of falsy)
		animate ? dotLottieRef.play() : dotLottieRef.pause()
	}, [animate])

	if (!isClient) {
		return null // or render a placeholder
	}

	const dotLottieRefCallback = dotLottie => {
		setDotLottieRef(dotLottie)
	}

	const animationSrc = animations[animation]
	const style = {width: size || width, height: size || height}

	const autoplay = play === 'auto'
	const playOnHover = play === 'hover'

	return (
		<Layout height={size} width={size}>
			<DotLottieReact
				src={animationSrc}
				style={style}
				loop
				autoplay={autoplay}
				playOnHover={playOnHover}
				dotLottieRefCallback={dotLottieRefCallback}
			/>
		</Layout>
	)
})
