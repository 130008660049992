import {PopoutCard} from '../../popout_card'

export const TruncateTooltip = ({textString, textElement, width, baseColor}) => (
	// Pass the baseColor to prevent the truncating Layout from setting a blue colorSet for an item that
	// should be coloured differently.

	<PopoutCard
		className="eitje-text-wrapper"
		layoutProps={{maxWidth: '100%', baseColor}}
		wrapperLayoutProps={{maxWidth: '100%', width, grow: true}}
		title={textString}
	>
		{textElement}
	</PopoutCard>
)
