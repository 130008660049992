import {ListPicker} from '../list_picker'
import {Checkbox} from '../checkbox'
import utils from '@eitje/utils'
import {Layout} from '../layout'
import {NumberInput} from '../number_input'
import {Text} from '../text'
import {useI18n} from '/src/i18n'
import {packageCosts} from '../../constants'
import {InputLayout} from './input_layout'
import {DISCOUNT_THRESHOLD} from './helpers'

const MAX_TEAM_MEMBER_AMOUNT = 100000

export const DiscountInputs = ({
	packageType,
	activeTeamMembers,
	setActiveTeamMembers,
	charityStatus,
	setCharitystatus,
	setPackageType,
	tierDiscount,
	setTierDiscount,
}) => {
	const [t] = useI18n()

	const items = Object.entries(packageCosts).map(([key]) => ({
		name: utils.capitalize(t(`common.tiers.${key}`)),
		id: key,
	}))

	const handleChange = val => {
		if (val >= 0 && val <= MAX_TEAM_MEMBER_AMOUNT) setActiveTeamMembers(val)
	}

	const tierDiscountActive = tierDiscount || activeTeamMembers >= DISCOUNT_THRESHOLD
	const _setTierDiscount = () => {
		const setTo = activeTeamMembers >= DISCOUNT_THRESHOLD ? true : !tierDiscount
		setTierDiscount(setTo)
	}

	return (
		<Layout className="discount-inputs" borderBottom padding="- - 20 -" direction="vertical" width="100%" gap={12}>
			<Text large bold>
				{t('cost_calculator.enter_data')}:
			</Text>
			<InputLayout>
				<Text
					chat
					popoutTitle="popouts.active_team_members.title"
					popoutBody={t('popouts.active_team_members.text')}
					t="cost_calculator.active_team_member_amount"
				/>
				<NumberInput value={activeTeamMembers} onChange={handleChange} />
			</InputLayout>
			<InputLayout>
				<Text t="cost_calculator.package" />
				<ListPicker value={packageType} items={items} onChange={setPackageType} minWidth={127} />
			</InputLayout>
			<InputLayout>
				<Text t="cost_calculator.tier_discount" />
				<Checkbox checked={tierDiscountActive} onClick={_setTierDiscount} />
			</InputLayout>
			<InputLayout>
				<Text chat popoutTitle="popouts.anbi.title" popoutBody={t('popouts.anbi.text')} t="cost_calculator.charity_discount" />
				<Checkbox checked={charityStatus} onClick={() => setCharitystatus(!charityStatus)} />
			</InputLayout>
		</Layout>
	)
}
