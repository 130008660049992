import {StaticImage} from 'gatsby-plugin-image'
import {Colors} from '/src/helpers'
import {Layout, Link} from '/src/components'
import {useWindowWidth, SimpleContextWrapper} from '/src/contexts'
import {MAX_HEADER_WIDTH} from '/src/constants'
import {LeftMenu} from './left_menu'
import {RightMenu} from './right_menu'
import {useHasScrolled} from './hooks'
import {MobileMenu} from './mobile_menu'
import './styles.less'

export const Menu = ({mobileMenuOpen, setMobileMenuOpen}) => {
	const hasScrolled = useHasScrolled()
	const {desktop} = useWindowWidth()
	const highContrast = (desktop && hasScrolled) || !desktop
	const backgroundColor = highContrast ? Colors.white : Colors.lightBackground
	const borderColor = highContrast ? Colors.mediumBorder : 'transparent'
	const contextValues = {setMobileMenuOpen}

	return (
		<SimpleContextWrapper {...contextValues}>
			<Layout
				className="eitje-menu"
				vertical="center"
				horizontal="center"
				style={{backgroundColor, borderColor}}
				borderBottom
				maxHeight="100vh"
			>
				<Layout horizontal="spaceBetween" maxWidth={MAX_HEADER_WIDTH} padding="- 24">
					<Layout gap={28}>
						<Link to="/" aria-label="Go to Homepage">
							<StaticImage
								src="../../images/logo_with_name.png"
								alt="Eitje - Homepage"
								title="Go to Homepage"
								width={100}
								loading="eager"
							/>
						</Link>
						{desktop && <LeftMenu />}
					</Layout>
					<RightMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
				</Layout>
			</Layout>
			{mobileMenuOpen && <MobileMenu />}
		</SimpleContextWrapper>
	)
}
