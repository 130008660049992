import {forwardRef, useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import _ from 'lodash'
import utils from '@eitje/web_utils'
import {useHideShow, usePopper, useConditionalInterval, useHover, useDelayedHover} from '../../hooks'
import {childrenWithClonedProps} from '../../helpers'
import {ClickOutsideManager} from '../click_outside_manager'
import {Layout} from '../layout'
import {useGetPopoutBody} from './popout_content'
import './styles/index.less'
import './styles/tooltip.less'

const Portal = ({children, className = 'root-portal', el = 'div'}) => {
	const [container] = useState(() => {
		return document.createElement(el)
	})

	useEffect(() => {
		container.classList.add(className)
		document.body.appendChild(container)
		return () => {
			try {
				document.body.removeChild(container)
			} catch (e) {
				console.log(e)
			}
		}
	}, [])

	return createPortal(<div onClick={e => e.stopPropagation()}>{children}</div>, container)
}

export const PopoutCard = forwardRef((props, ref) => {
	const {
		children,
		className,
		trigger = props.onConfirm ? 'click' : 'hover',
		shouldAlwaysRender,
		hidden,
		inlineTrigger,
		disabled,
		onConfirm,
		title,
		onVisibleChange = _.noop,
		layoutProps = {},
		style,
		wrapperLayoutProps,
		delayedHovering,
		displayContents,
		onClose,
	} = props
	const initialMaxHeight = props.maxHeight || 1000
	const [maxHeight, setMaxHeight] = useState(initialMaxHeight)
	const [internalOpen, setInternalOpen] = useState()
	const internalRef = useRef()
	ref = ref || internalRef
	const open = _.has(props, 'open') ? props.open : internalOpen
	const containerRef = useRef()
	let {hoverActions, isHovering, setHovering} = useHover()
	const delayedIsHovering = useDelayedHover(isHovering)
	const hoverEnabled = trigger === 'hover'
	const isTooltip = title && !props.body && !onConfirm
	const isPopoutHoverable = !isTooltip || !_.isString(title)

	const handleClick =
		hidden || hoverEnabled
			? e => {
					props.onClick?.(e) // should actually also put this in the other condition, but let's keep it minimal for now to see the impact
				}
			: e => {
					e.preventDefault()
					e.stopPropagation()
					setInternalOpen(!open)
					if (!open) setMaxHeight(props.maxHeight)
				}

	isHovering = delayedHovering ? delayedIsHovering : isHovering
	const hasDocument = typeof document !== 'undefined'
	const hasContent = title || props.body
	const showPopout = !disabled && !hidden && hasContent && (open || (hoverEnabled && isHovering))

	useHideShow({
		ref,
		setIsOpen: setInternalOpen,
		isOpen: showPopout,
		setHovering,
	})

	// To fix the issue with tooltips not closing due to onMouseLeave not being triggered correctly
	// Once openend, we check each second if the user is still hovering and if not we programatically close it by setting isHovering to false
	useConditionalInterval(
		showPopout,
		() => {
			const isPopoutCurrentlyHovered = Array.from(document.querySelectorAll(':hover')).includes(containerRef.current)
			if (!isPopoutCurrentlyHovered && isTooltip) {
				setHovering(false)
			}
		},
		1000,
	)

	useEffect(() => {
		if (!showPopout) setMaxHeight(initialMaxHeight)
		onVisibleChange(showPopout)
	}, [showPopout])

	const boundaryElement = hasDocument && document.querySelector('.popout-boundary')
	const popoutInBoundary = boundaryElement?.contains?.(containerRef.current)

	const {wrapperProps, styles, referenceElement, popperElement, arrowElement} = usePopper({
		...props,
		boundaryElement: popoutInBoundary && boundaryElement,
		isTooltip,
		showPopout,
		containerRef,
		maxHeight,
		setMaxHeight,
	})

	const containerClassNames = utils.makeCns(
		'popout-card',
		inlineTrigger && 'popout-card-inline',
		displayContents && 'display-contents',
		className,
	)

	const popoutCnVariants = utils.makeCnVariants(
		'popout-wrapper',
		showPopout && 'visible',
		isTooltip && 'is-tooltip',
		isPopoutHoverable && 'is-hoverable',
	)
	const popoutClassNames = utils.makeCns(
		popoutCnVariants,
		className
			?.split(' ')
			.map(c => `${c}-popout`)
			.join(' '),
	)

	const triggerClassNames = utils.makeCns(
		'popout-card-trigger',
		className
			?.split(' ')
			.map(c => `${c}-trigger`)
			.join(' '),
		displayContents && 'display-contents',
	)
	const {header, body, footer} = useGetPopoutBody({
		...props,
		setHovering,
		isTooltip,
		ref,
		maxHeight,
	})

	return (
		<Layout ref={containerRef} {...hoverActions} className={containerClassNames} {...wrapperLayoutProps} style={style}>
			<Layout
				ref={referenceElement}
				onClick={handleClick}
				className={triggerClassNames}
				hasHover={!hidden}
				disabled={disabled}
				width="100%"
				{...layoutProps}
			>
				{childrenWithClonedProps({
					children,
					props: {disabled},
					condition: disabled,
				})}
			</Layout>

			{(showPopout || shouldAlwaysRender) && (
				<Portal>
					<div ref={popperElement} className={popoutClassNames} {...wrapperProps}>
						{!hoverEnabled && (
							<ClickOutsideManager
								ref={containerRef}
								onClick={() => {
									onClose?.()
									setInternalOpen(false)
								}}
							/>
						)}
						{isPopoutHoverable && hoverEnabled && <div className="hover-zone" />}
						<Layout className="popout-content" direction="vertical" gap={0}>
							{header}
							{body}
							{footer}
						</Layout>
						{isTooltip && <div className="tooltip-arrow" ref={arrowElement} style={styles.arrow} />}
					</div>
				</Portal>
			)}
		</Layout>
	)
})

// Placement
//  auto
//  auto-start
//  auto-end
//  top
//  top-start
//  top-end
//  bottom
//  bottom-start
//  bottom-end
//  right
//  right-start
//  right-end
//  left
//  left-start
//  left-end
