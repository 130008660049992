import {Link} from 'gatsby'
import {BaseEitjeButton} from './base'
import {useWindowWidth} from '/src/contexts'
import {useLocaleUrl} from '/src/hooks'
import './styles.less'

export * from './buttons'

export const EitjeButton = props => {
	let {height = 'large', link, ...rest} = props
	const {mobile} = useWindowWidth()

	if (link) return <LinkButton {...props} />

	if (mobile && height === 'large') height = 'regular'

	return <BaseEitjeButton fontFamily="raleway" height={height} {...rest} />
}

export const LinkButton = ({link, style, ...rest}) => {
	const {url, target, external} = useLocaleUrl(link)

	if (external)
		return (
			<a href={url} target={target} style={{display: 'contents'}}>
				<EitjeButton className="link-button" {...rest} />
			</a>
		)

	return (
		<Link to={url} target={target}>
			<EitjeButton className="link-button" width="full" {...rest} />
		</Link>
	)
}
