export const animations = {
  apple: 'https://lottie.host/88aced5d-dad4-4dad-81da-bfe1b146fc57/5M7bCSxmZc.lottie',
  avatar: 'https://lottie.host/6e8355a9-440a-4d10-9102-244e1f4788ae/kazNv5VZJX.lottie',
  'bar-chart': 'https://lottie.host/81e9e601-7908-4dae-aea3-b6e97f561c77/I5hdcbgQUe.lottie',
  binocular: 'https://lottie.host/7a7733df-b897-45c2-9212-a08d7b1cfb80/GQbTsMyjCP.lottie',
  'bird-with-mail': 'https://lottie.host/15f77329-beda-442b-8574-f995427a20df/sluJHG6HoF.lottie',
  'book-with-glasses': 'https://lottie.host/94a7f339-eaf5-4a96-8d1d-d97f29e7288e/kXyZYtz1x7.lottie',
  'broken-egg': 'https://lottie.host/0b361605-a7e6-44e1-b0b0-68c2a89dcfb1/I2kyJ5IDBB.lottie',
  calendar: 'https://lottie.host/88b3d6bc-d27c-4f27-b4da-82f28c000460/g8CQ2WucsS.lottie',
  chat: 'https://lottie.host/248bdc7e-3ef7-4732-957d-798aa1b0acb6/9KfSNjIqnP.lottie',
  chick: 'https://lottie.host/94114889-fbbb-4ecf-817b-4e82af3c71ae/UQhpJMBXa3.lottie',
  clock: 'https://lottie.host/a4b979d1-4eb9-4f69-9922-c6df7b3cc7a9/7wJob7sSO6.lottie',
  'cloud-network': 'https://lottie.host/088925bf-8c15-43d6-b864-40d310bca217/jxNO27EG60.lottie',
  cogwheel: 'https://lottie.host/42361d5d-ab44-4895-9a8e-35cb8a969925/Njlumz2P8p.lottie',
  coins: 'https://lottie.host/3ca0c625-f495-41e5-8712-fd2026d53a6d/vyqYQDmAq7.lottie',
  confetti: 'https://lottie.host/cfc506be-bbab-439c-aa4c-64e7111b5236/UhwqesWdPG.lottie',
  'construction-worker': 'https://lottie.host/2164f705-6b08-43ca-a7e9-db0421a1c003/6PKBdUTTUg.lottie',
  contract: 'https://lottie.host/263ffc6b-7e1d-4e5b-b51f-424d60083bfd/yte3Tmzw3Q.lottie',
  'credit-card-and-lock': 'https://lottie.host/c4a64490-f1f2-4c75-94cf-030542d7f959/aApez2CpZs.lottie',
  desert: 'https://lottie.host/c9c150d8-d5b8-4d2d-8439-8c3e57174205/W3CyRzhHU5.lottie',
  download: 'https://lottie.host/34a24f43-4092-4e36-b10b-9c60759b5c44/s7aTK62Qnw.lottie',
  'egg-with-ribbon': 'https://lottie.host/376a6369-6d8e-4fe4-8ce8-8946e027ab4b/1Fm9hj4BMS.lottie',
  excel: 'https://lottie.host/9be40e23-94dc-46c5-973a-602a04a36c77/Wl5M1tftHb.lottie',
  eye: 'https://lottie.host/d105be9f-e136-461c-80fc-2fa1c572d502/AEzTMQC9a0.lottie',
  'file-transfer': 'https://lottie.host/f287b2be-0d28-4075-ba67-787aa9335d82/SS5qLBJA81.lottie',
  'file-with-barchart': 'https://lottie.host/842ae48b-f575-46b1-b86b-c295d11cddb9/KOonHe8ycA.lottie',
  filter: 'https://lottie.host/b9f15631-a57f-41bd-b0df-5f95449385e7/PeGL9LRpPR.lottie',
  'food-delivery': 'https://lottie.host/638b3de4-1b7a-47a2-86df-562e46bcf54a/jpVsAKkVHy.lottie',
  'gear-and-document': 'https://lottie.host/df586c9e-561e-4caf-ab62-02e3ad954715/Xqw3U06056.lottie',
  hammer: 'https://lottie.host/72501a2a-59b1-4144-b20b-258880603043/qzNp8f0oKk.lottie',
  'high-five': 'https://lottie.host/d7177930-f16e-49d5-88da-0cac10955215/14xwM1pbv3.lottie',
  hourglass: 'https://lottie.host/e943a4ac-c9c0-43d0-bebd-518ab79c5dc6/Srr9ks9VAf.lottie',
  internet: 'https://lottie.host/e8b52995-de98-4a02-bd21-16860456ff90/O6xbuTvzO6.lottie',
  'light-bulb': 'https://lottie.host/cc621b89-a955-407e-95ae-ed7050b04dcf/6jbJM7ZvCY.lottie',
  location: 'https://lottie.host/ed0803fd-285f-4f40-b5fe-fb342317f528/WNXppyhcTP.lottie',
  'magnifying-glass': 'https://lottie.host/44f59911-5ceb-4fe1-b439-596a312bb2e5/oI3PkMzNpk.lottie',
  'mobile-apps': 'https://lottie.host/f47653d2-2cca-42b0-9616-702e42bffb60/8nA0W7riZq.lottie',
  'money-with-arrows-up': 'https://lottie.host/0207db19-b847-4529-bdba-dc8227c3a75d/54DKWqxaJm.lottie',
  nest: 'https://lottie.host/8cd13047-a237-4e73-b337-8a528f476ed2/C38L5hGwDq.lottie',
  'nest-in-tree': 'https://lottie.host/48be80d9-a8b7-4776-b3a7-17685d4b5afe/qpijaT8A6K.lottie',
  notebook: 'https://lottie.host/bf36634a-dfd5-455c-82cb-73c10273a3a1/JEvOADH1qR.lottie',
  pencil: 'https://lottie.host/a7de692e-1860-42f5-b559-0e75ff4a9225/asIz8wshy3.lottie',
  'pinned-note': 'https://lottie.host/7e358899-4e88-46ac-b71d-9c7d6ff0da71/abnnKkVwAq.lottie',
  plane: 'https://lottie.host/84f1a750-a5c7-4c22-b356-c422d1280cca/rQmvmmZcgC.lottie',
  'pull-ups': 'https://lottie.host/fbd0ad65-2e76-4380-b306-58b83dbe6edc/2fi63SFS9g.lottie',
  receipt: 'https://lottie.host/3c2b5588-a5f5-46c4-b7c8-f82b46fb0f43/RXsZFb6RS0.lottie',
  'ringing-bell': 'https://lottie.host/f4863785-7064-452b-8f9f-9e4a5e087219/o9DeDbaaMX.lottie',
  roster: 'https://lottie.host/1280efe6-d0bc-45a8-86da-0922fe37ad5f/LHexumG2zn.lottie',
  'search-money': 'https://lottie.host/c02b0b39-faed-41e0-b48a-e8ddbc3a1021/YGadQd6xhy.lottie',
  stopwatch: 'https://lottie.host/ad1115e3-24ad-42a1-a2af-35342cecb1f6/GPgAkdlPFA.lottie',
  sunbathing: 'https://lottie.host/5e0d4fdc-d61c-4589-a88f-257eccb00956/O3UKRE0qpn.lottie',
  support: 'https://lottie.host/640ad307-fb4f-484e-a943-64d0a0c9dfa6/MkpcAZAYPg.lottie',
  'tablet-touch-screen': 'https://lottie.host/64172a1f-b0a6-4e7c-9b7e-fce99dc0beef/g0HbiCWlLK.lottie',
  'target-and-briefcase': 'https://lottie.host/71a632c6-0ac1-484d-8a07-cd845a2abb7b/ZZxVZ3FDSe.lottie',
  'three-people-with-pointing-finger': 'https://lottie.host/18e889f6-6c4d-4a72-ad83-a746eae26d15/ccPXEvcg2T.lottie',
  training: 'https://lottie.host/4968f9ca-570f-48d7-bf4f-62a8cd7899ba/KbxR7EELRX.lottie',
  'trophy-number-one': 'https://lottie.host/8eb09c1f-4ac4-4603-af83-403d773b248f/lolfrJymiW.lottie',
  upload: 'https://lottie.host/f076f4ed-1c3e-4bd0-ab90-b12f9a734415/Gs4DjowDrt.lottie',
  'waving-people': 'https://lottie.host/3d229076-8249-47d0-a6ea-5a5209794643/KRuAIlD8Tr.lottie',
  'weighing-scale': 'https://lottie.host/7f5c2e2c-cbc6-447f-b394-0a020f8ecdf6/8yEcmFc5JY.lottie',
  'winning-man': 'https://lottie.host/9aca1a41-5bc7-4f26-b890-562b98c9dfa9/mJpj5GXGbu.lottie',
}
