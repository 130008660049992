import {useEffect, useRef} from 'react'

export const useConditionalInterval = (condition, callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      if (condition) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [condition, delay])
}
