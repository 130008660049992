import {cloneElement, useRef} from 'react'
import utils from '@eitje/web_utils'
import {Layout} from '../layout'
import {Text} from '../text'
import {useHasPrefix} from './hooks/useHasPrefix'

export const useGetPopoutBody = props => {
	const {
		title,
		isTooltip,
		header: _header,
		body: _body,
		footer: _footer,
		maxHeight,
		ref,
		bodyLayoutProps = {},
		footerLayoutProps = {},
		basePadding = 12,
		bodyPadding,
		noBorders,
	} = props
	const bodyRef = useRef()
	const anyElementHasPrefix = useHasPrefix(bodyRef)

	// HEADER
	const DefaultHeader =
		!_header &&
		title &&
		(isTooltip ? (
			<Layout className="popout-title">{title}</Layout>
		) : (
			<Layout className="popout-header" padding={basePadding} width="100%" borderBottom={!noBorders}>
				<Text fontSize="18px" bold t={title} />
			</Layout>
		))

	const header = _header
		? cloneElement(_header, {
				className: utils.makeCns(_header.props.className, 'popout-header'),
			})
		: DefaultHeader

	// BODY
	const bodyElement = utils.funcOrVal(_body, ref?.current)
	const bodyClassNames = utils.makeCns(utils.makeCnVariants('popout-body', anyElementHasPrefix && 'has-prefix'), bodyLayoutProps.className)

	const verticalPadding = title ? 0 : 12

	const body = !isTooltip && (
		<Layout
			ref={bodyRef}
			className={bodyClassNames}
			maxHeight={maxHeight}
			width="100%"
			direction="vertical"
			padding={noBorders ? `${verticalPadding} 12` : _.isNumber(bodyPadding) ? bodyPadding : basePadding}
			{...bodyLayoutProps}
		>
			{bodyElement}
		</Layout>
	)

	const footerClassNames = _footer && utils.makeCns(_footer.props?.className, 'popout-footer')

	// Footer
	const footer = _footer && (
		<Layout className={footerClassNames} padding={basePadding} borderTop={!noBorders} width="100%" {...footerLayoutProps}>
			{_footer}
		</Layout>
	)

	return {header, body, footer}
}
