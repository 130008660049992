import utils from '@eitje/web_utils'
import {Icon} from '../../icon'
import {Layout} from '../../layout'
import {Text} from '../../text/base'
import {useI18nContext} from '/src/contexts'
import './styles/index.less'

const PADDING = [0, 16]
const SMALL_PADDING = [0, 12]
const MINI_PADDING = [0, 8]

export const BaseEitjeButton = props => {
	let {
		height = 'regular',
		width,
		dropdown,
		iconLeft,
		iconRight,
		iconColor,
		iconLeftProps,
		iconRightProps,
		colorSet = 'grey-bordered',
		baseColor = 'blue',
		hasHover = true,
		children,
		className,
		t = {},
		onClick,
		truncate = true,
		padding,
		fontFamily,
		...rest
	} = props
	const {t: parseT} = useI18nContext()
	const content = children || parseT(t)
	const iconOnly = (iconLeft || iconRight) && !content

	iconRight = dropdown ? 'chevron-small-down' : iconRight

	const classNames = utils.makeCns(
		'eitje-button',
		className,
		hasHover && 'pointer-cursor',
		colorSet === 'initial-transparent' && 'no-shadow',
	)

	const isMini = height === 'mini'
	const isSmall = height === 'small'
	// const isRegular = height === 'regular'
	const isLarge = height === 'large'
	const iconSize = isMini ? '10px' : '14px'
	const weight = {[isMini ? 'semiBold' : 'bold']: true}
	const _height = isMini ? 24 : isSmall ? 32 : isLarge ? 48 : 40

	const renderIcon = (icon, placement, iconProps) => {
		if (!icon) return null
		return <Icon name={icon} size={iconSize} className={`icon-${icon}-${placement}`} initialFill={iconColor} {...iconProps} />
	}

	if (!padding) {
		padding = PADDING
		if (isMini) padding = MINI_PADDING
		if (isSmall) padding = SMALL_PADDING
		if (isSmall && iconOnly) padding = MINI_PADDING
	}

	return (
		<Layout
			{...rest}
			onClick={onClick}
			className={classNames}
			padding={padding}
			colorSet={colorSet}
			hasHover={hasHover}
			baseColor={baseColor}
			width={width}
			height={_height}
			gap={isMini ? 4 : 8}
		>
			{renderIcon(iconLeft, 'left', iconLeftProps)}
			{content && !iconOnly && (
				<Text
					size={isMini ? 'small' : isLarge && 'large'}
					{...weight}
					className="eitje-button-text"
					noTextSelect
					truncate={truncate}
					fontFamily={fontFamily}
				>
					{utils.capitalize(content)}
				</Text>
			)}
			{renderIcon(iconRight, 'right', iconRightProps)}
		</Layout>
	)
}
