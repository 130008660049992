import {useReferenceElement} from '../components/popout_card/hooks/use_reference_element'
import {useEffect, useState} from 'react'
import {usePopper as _usePopper} from 'react-popper'

const BOTTOM_MARGIN = 20

export const usePopper = props => {
  const {
    offset = [0, 8],
    boundaryElement,
    onConfirm,
    forceUpdateDependency,
    isTooltip,
    width,
    minWidth,
    maxWidth,
    showPopout,
    strategy,
    maxHeight,
    setMaxHeight,
    displayContents,
  } = props
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const placement = props.placement || (isTooltip || onConfirm ? 'top' : 'bottom')

  const virtualElement = useReferenceElement({referenceElement, showPopout})

  const modifiers = [
    {name: 'arrow', options: {element: arrowElement}},
    {
      name: 'offset',
      options: {
        offset,
      },
    },
    ...(boundaryElement
      ? [
          {
            name: 'preventOverflow',
            options: {
              boundary: boundaryElement,
            },
          },
        ]
      : []),
  ]

  const popperProps = _usePopper(showPopout && (displayContents ? virtualElement : referenceElement), popperElement, {
    placement,
    modifiers,
    strategy,
  })

  useEffect(() => {
    // popper.js function to recalculate popper position
    popperProps?.forceUpdate?.()
  }, [forceUpdateDependency])

  useEffect(() => {
    const height = popperElement?.offsetHeight
    const top = getTranslate3d(popperProps.styles?.popper) - window.scrollY
    const popperOverflowsPageBottom = top + height + BOTTOM_MARGIN > window.innerHeight
    const sizeDecreasedPerStep = 100

    if (popperOverflowsPageBottom && !popperProps?.state?.placement?.includes('top')) {
      setMaxHeight(prevMaxHeight => {
        const newMaxHeight = (prevMaxHeight || height) - sizeDecreasedPerStep
        if (newMaxHeight !== prevMaxHeight && newMaxHeight > sizeDecreasedPerStep) {
          popperProps?.update?.()
          return newMaxHeight
        }
        return prevMaxHeight
      })
    }
  }, [popperElement, popperProps.styles, popperProps?.state?.placement])

  const wrapperProps = {
    style: {...popperProps?.styles.popper, width, minWidth, maxWidth},
    onMouseDown: e => e.stopPropagation(),
    ...popperProps.attributes.popper,
  }

  return {
    ...popperProps,
    referenceElement: setReferenceElement,
    arrowElement: setArrowElement,
    popperElement: setPopperElement,
    wrapperProps,
  }
}

function getTranslate3d(el) {
  if (!el?.transform) return 0
  // Split the 'transform' property value of the element into an array of values.
  // The split is performed by matching either one or more word characters
  // followed by an opening parenthesis, or a closing parenthesis followed by an optional semicolon.
  const values = el.transform.split(/\w+\(|\);?/)

  if (!values[1] || !values[1].length) {
    return []
  }

  // Split the value at index 1 by a comma followed by an optional whitespace, creating an array of sub-values.
  // Access the element at index 1 of the resulting array, and replace the 'px' string with an empty string.
  // Finally, parse the resulting value as an integer and return it.
  return parseInt(values[1].split(/,\s?/g)?.[1]?.replace('px', ''))
}
