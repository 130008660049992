import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {useState, useEffect} from 'react'
import {Helmet as Head} from 'react-helmet'
import utils from '@eitje/utils'
import {I18nProviderWrapper, useWindowWidth} from '/src/contexts'
import {useStoreOrQueryParams} from '/src/hooks'
import {Layout, Footer, SEO, Menu} from '/src/components'
import './styles.less'

const inlineStyles = {
	backgroundColor: 'white',
}

const Inner = ({children, className, hideFooter}) => {
	const {desktop} = useWindowWidth()
	const [mobileMenuOpen, setMobileMenuOpen] = useState()
	const mobileMenuIsActuallyOpen = !desktop && mobileMenuOpen
	const {queryParams, loaded} = useStoreOrQueryParams()

	useEffect(() => {
		if (loaded) {
			const {c, m, s, t} = queryParams
			if (c && heap && heap.identify && heap.addUserProperties) {
				heap.identify(c)
				heap.addUserProperties({mail_id: m, template_id: t, sequence_id: s})
			}
		}
	}, [loaded])

	const classNames = utils.makeCns('page', className)

	return (
		<div className={classNames}>
			<Head>
				<title>Eitje | Breng je hele team samen met eitje</title>
				<meta name="viewport" content="width=device-width,initial-scale=1.0" />
				<link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
			</Head>
			<SEO />

			<Layout className="application-headers" fixed="0 - - -" width="100vw" direction="vertical" style={inlineStyles} gap={0}>
				<Menu setMobileMenuOpen={setMobileMenuOpen} mobileMenuOpen={mobileMenuIsActuallyOpen} />
			</Layout>
			{!mobileMenuIsActuallyOpen && <div className="page-body">{children}</div>}
			{!hideFooter && !mobileMenuIsActuallyOpen && <Footer />}
		</div>
	)
}

export const HeaderAndFooter = props => {
	return (
		<I18nProviderWrapper>
			<Inner {...props} />
		</I18nProviderWrapper>
	)
}
