import {useCallback} from 'react'
import utils from '@eitje/web_utils'
import {Colors} from '../../helpers'
import {isBoolean, isString} from 'lodash'

export const validCssSize = size => {
	const knownUnits = ['em', 'rem', 'px', 'vh', '%', 'vw', 'calc(', 'auto', 'unset']

	if (!isNaN(size)) return `${size}px`

	return typeof size === 'string' && knownUnits.some(unit => size.includes(unit)) && size
}

export const formatCssVars = obj => {
	return Object.keys(obj).reduce((result, key) => {
		result['--' + key] = obj[key]
		return result
	}, {})
}

export const getSpacing = (spacing, type) => {
	if (spacing) {
		let spacingArray = isString(spacing) ? spacing.split(' ') : utils.alwaysArray(spacing)
		const [top, right = top, bottom = top, left = right] = spacingArray
		const properties = {top, right, bottom, left}

		const result = {}
		Object.keys(properties).forEach(key => {
			const value = properties[key]
			if (value !== '-') {
				const cssProperty = type ? `${type}${utils.capitalize(key)}` : key
				result[cssProperty] = validCssSize(value)
			}
		})
		return result
	}
}

export const getAlignment = align => {
	if (align === 'start' || align === 'last-item-end') return 'flex-start'
	if (align === 'end') return 'flex-end'
	if (align === 'center') return 'center'
	if (align === 'spaceBetween') return 'space-between'
}

const getAlignValues = ({direction, childrenGrow, childrenEqual, ...rest}) => {
	const align = getAlignment(rest[direction])
	const hasAlignProperty = align || childrenGrow || childrenEqual
	return {align, hasAlignProperty}
}

const getWidthProperty = ({horizontalMargin, fullMinusMargin, fullWidth}) => {
	if (fullMinusMargin) return `calc(100% - ${horizontalMargin * 2}px)`
	if (fullWidth) return '100%'
	return undefined
}

const sharedStyles = ({cssWidth, cssHeight, fullWidth, fullHeight, align, grow, width}) => {
	const fullMinusMargin = width?.includes?.('full-minus-margin')
	const horizontalMargin = parseInt(width?.split?.('full-minus-margin-')[1] || 12)
	let styles = {
		width: cssWidth || getWidthProperty({horizontalMargin, fullWidth, fullMinusMargin}),
		height: cssHeight || (fullHeight ? '100%' : undefined),
		justifyContent: align,
		flexGrow: grow && 1,
	}

	if (fullMinusMargin) styles.marginLeft = horizontalMargin
	return styles
}

export const horizontalStyles = props => {
	const {vertical, width, height, grow, wrap} = props

	const {align, hasAlignProperty} = getAlignValues(props)
	const fullWidth = width === 'full' || hasAlignProperty
	const fullHeight = height === 'full'
	const styles = {
		...sharedStyles({...props, fullWidth, fullHeight, align}),
		flexDirection: 'row',
		alignItems: vertical || 'center',
		flexShrink: grow ? 'unset' : !!width && 0,
		flexWrap: wrap && 'wrap',
	}

	return styles
}

export const verticalStyles = props => {
	const {horizontal, width, height, grow} = props

	const {align, hasAlignProperty} = getAlignValues(props)
	const fullWidth = width === 'full'
	const fullHeight = height === 'full' || hasAlignProperty

	const styles = {
		...sharedStyles({...props, fullWidth, fullHeight, align}),
		flexDirection: 'column',
		alignItems: horizontal || 'flex-start',
		flexShrink: grow ? 'unset' : !!height && 0,
	}

	return styles
}

export const getPosition = (positions, position) => {
	if (!positions) return {}
	const sides = getSpacing(positions)

	return {
		position,
		...sides,
	}
}

export const getBorderRadius = radius => {
	if (!radius) return
	if (isBoolean(radius)) radius = '4px'
	if (radius === 'large') radius = '8px'
	return {borderRadius: radius}
}

export const colorVariables = baseColor => {
	let variables = {}
	const colorIntensities = ['light', 'midtone', 'medium', 'dark']

	colorIntensities.forEach(intensity => {
		variables[`--${intensity}-color`] = Colors[`${intensity}${utils.capitalize(baseColor)}`]
	})
	return variables
}

// The ref needed for react-popper is a useState
// To accomadate for both this ref to work and have an actual ref to the DOM
// we have this hook that chooses the right type

export const useMergedRefs = (...refs) => {
	const setRefs = useCallback(
		node => {
			refs.forEach(ref => {
				if (!ref) return
				if (typeof ref === 'function') {
					ref(node)
				} else {
					ref.current = node
				}
			})
		},
		[refs],
	)

	return setRefs
}

const borders = ['top', 'right', 'bottom', 'left']

export const borderStyles = props => {
	if (props.border) return ['border']
	return borders
		.filter(border => {
			const key = `border${utils.capitalize(border)}`
			return props[key]
		})
		.map(border => `border-${border}`)
}
