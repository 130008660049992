import {Colors} from '/src/helpers'
import {Image} from '../image'
import {Layout, getResp} from '../layout'
import {Text} from '../text'

export const UserInfo = ({image, name, nameColor = 'darkGrey', size = 'regular', subtext, ...rest}) => {
	const isSmall = size === 'small'
	const imageSize = isSmall ? 44 : 60
	return (
		<Layout gap={12} {...rest}>
			<Image className="user-image" alt="user avatar" image={image} height={imageSize} width={imageSize} borderRadius="50%" />
			<Layout direction="vertical" gap={0} horizontal={image ? 'start' : 'center'}>
				<Text bold color={Colors[nameColor]} children={name} />
				<Text darkGrey children={subtext} />
			</Layout>
		</Layout>
	)
}
