import {CollapseChevron} from '../collapse_chevron'
import {useWindowWidth} from '/src/contexts'
import {Colors} from '/src/helpers'
import {Layout} from '../layout'
import {Text} from '../text'

export const Column = ({name, title, isFirst, subtitle, open, tableName, ...rest}) => {
	const {desktop} = useWindowWidth()
	const viewport = desktop ? 'regular' : 'small'
	const bigStyling = isFirst || desktop
	const cellT = `table.${tableName}.${viewport}.${name}`

	return (
		<Layout height="100%" padding="8 12" direction="vertical" vertical="center" gap={0} {...rest}>
			<Layout gap={8}>
				{isFirst && <CollapseChevron open={open} />}
				<Text bold={bigStyling} large={bigStyling} t={title || `${cellT}.title`} />
			</Layout>
			{subtitle && desktop && <Text darkGrey t={`${cellT}.subtitle`} />}
		</Layout>
	)
}

const inlineStyles = {zIndex: 2}

export const SectionHeader = ({title, setIsOpen, open, tableName, columns, columnWidths}) => {
	return (
		<Layout
			className="feature-table-section-header"
			borderBottom
			colorSet="black-bordered"
			initialBackground={Colors.lightBackground}
			style={inlineStyles}
			width="100%"
			gap={0}
			height={[50, 50, 80]}
			onClick={() => setIsOpen(!open)}
		>
			<Column grow isFirst title={title} open={open} tableName={tableName} />
			{columns.map(column => (
				<Column horizontal="center" width={columnWidths} subtitle name={column} tableName={tableName} />
			))}
		</Layout>
	)
}
