import utils from '@eitje/web_utils'
import {Layout} from '../../layout'
import {PopoutCard} from '../../popout_card'
import {Icon} from '../../icon'
import {Colors} from '../../../helpers'
import {TruncateTooltip} from './truncate_tooltip'

const INFO_ICON_WIDTH = 18

export const InfoPopout = ({
	textString,
	textElement,
	truncate,
	iconName = 'question-mark',
	PopoutComponent = PopoutCard,
	showTruncationTooltip,
	t,
	...rest
}) => {
	const popoutClassNames = utils.makeCnVariants('info-popout', iconName)

	return (
		<Layout className="info-popout-container" data-heap-id={t} vertical="center" {...(truncate && {width: 'full'})}>
			{showTruncationTooltip ? (
				<TruncateTooltip textElement={textElement} textString={textString} width={`calc(100% - ${INFO_ICON_WIDTH}px`} />
			) : (
				textElement
			)}
			<PopoutComponent {...rest} className={popoutClassNames} layoutProps={{gap: 0}}>
				<Icon name={iconName} fill={Colors.white} size={16} />
			</PopoutComponent>
		</Layout>
	)
}
