import {Icon} from '../icon'
import {useLocation} from '@reach/router'
import {Link} from 'gatsby'
import {calendlyUrl} from '../../constants'
import {useIsTranslationIgnored} from '../../helpers/change_language'
import {switchLanguageUrl} from './helpers'
import {useT} from '../../i18n'
import {TryEitjeButton} from '../eitje_button/buttons'
import {Layout} from '../layout'
import {Text} from '../text'
import {MenuLink} from './components'
import {useWindowWidth} from '/src/contexts'

export const RightMenu = ({mobileMenuOpen, setMobileMenuOpen}) => {
	const location = useLocation()
	const {desktop} = useWindowWidth()
	const {lng} = useT()
	const isIgnored = useIsTranslationIgnored()

	const to = switchLanguageUrl(lng, location.pathname)

	if (desktop)
		return (
			<Layout gap={28}>
				<MenuLink link={calendlyUrl} title="links.book_demo" />
				<MenuLink link="https://web.eitje.app/login" title="login" />
				{!isIgnored && (
					<Link className="eitje-link eitje-link-no-initial-styles" to={to}>
						<Layout className="menu-language">
							<Icon name="world" />
							<Text black fontFamily="system-ui">
								{lng.toUpperCase()}
							</Text>
						</Layout>
					</Link>
				)}
				<TryEitjeButton height="regular" />
			</Layout>
		)

	return (
		<Layout gap={16}>
			{!mobileMenuOpen && <TryEitjeButton height="small" shortText />}
			<Icon onClick={() => setMobileMenuOpen(!mobileMenuOpen)} type="bold" size={20} name={mobileMenuOpen ? 'cross' : 'three-lines'} />
		</Layout>
	)
}
