import {ReactSVG} from 'react-svg'
import _ from 'lodash'
import {Colors} from '/src/helpers'
import utils from '@eitje/utils'
import './styles.less'

export const Blob = ({color, number, position = 'center', height, width, top = 0, horizontalBlobOffset = '-150px', ...rest}) => {
	if (!number) return null
	let IconModule

	try {
		IconModule = require(`./blobs/blob${number}.svg`)
	} catch (e) {
		return null
	}

	const center = position === 'center'
	const horizontalPosition = center ? 'left' : position
	const translateY = top === '50%' ? 'translateY(-50%) ' : ''
	const translateX = center ? 'translateX(-50%)' : ''

	const style = {
		top: _.isNumber(top) ? `-${top}px` : top,
		[horizontalPosition]: center ? '50%' : horizontalBlobOffset,
		transform: `${translateY}${translateX}`,
	}

	const beforeInjection = svg => {
		const lightPath = svg.querySelector('.blob-light')
		const midtonePath = svg.querySelector('.blob-midtone')
		const mediumPath = svg.querySelector('.blob-medium')

		width && svg.setAttribute('width', width)
		height && svg.setAttribute('height', height)

		if (lightPath) lightPath.style.fill = Colors[`light${utils.capitalize(color)}`]
		if (midtonePath) midtonePath.style.fill = Colors[`midtone${utils.capitalize(color)}`]
		if (mediumPath) mediumPath.style.fill = Colors[`medium${utils.capitalize(color)}`]
	}

	return (
		<ReactSVG
			className="blob-container"
			beforeInjection={beforeInjection}
			src={IconModule.default}
			wrapper="span"
			style={style}
			{...rest}
		/>
	)
}
