import {useState} from 'react'
import {useNodeAddedObserver} from '../../../hooks'

const excludeIcons = ['.dropdown-arrow-icon', '.info-popout']
const checkExcludedIcons = node => excludeIcons.every(icon => !node.closest(icon))

export const useHasPrefix = ref => {
	const [anyElementHasPrefix, setAnyElementHasPrefix] = useState()

	const checkForSvgs = () => {
		// This needs to live here and not in EitjeDropdown because EitjeDropdown doesn't know when the body is in the DOM
		// We check if there are any svg icons there to see if we need to indent other items
		// we exlude the icons that can be visible on the right side of the item, since this does not require indentation
		let svgs = ref.current?.querySelectorAll('.injected-svg')
		const hasPrefix = svgs?.length && !!Array.from(svgs)?.filter?.(checkExcludedIcons)?.length
		setAnyElementHasPrefix(hasPrefix)
	}

	useNodeAddedObserver({
		cb: checkForSvgs,
		ref,
		nodeName: 'svg',
	})

	return anyElementHasPrefix
}
