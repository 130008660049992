import {Fragment, forwardRef, useRef} from 'react'
import _ from 'lodash'
import {useI18nContext, useWindowWidth} from '/src/contexts'
import {navigate} from 'gatsby'
import utils from '@eitje/web_utils'
import {childrenWithClonedProps} from '../../helpers/children'
import {PopoutCard} from '../popout_card'
import {
	colorVariables,
	horizontalStyles,
	useMergedRefs,
	verticalStyles,
	getPosition,
	borderStyles,
	getSpacing,
	getBorderRadius,
	validCssSize,
	formatCssVars,
} from '../layout/helpers'

import './styles/index.less'
import './styles/debug.less'

export const Layout = forwardRef(({extLink, to, onClick, width, height, maxWidth, gap, marginTop, margin, ...rest}, forwardRef) => {
	width = getResponsiveValue(width)
	gap = getResponsiveValue(gap)
	height = getResponsiveValue(height)
	margin = marginTop ? `${getResponsiveValue(marginTop)} - - -` : getResponsiveValue(margin)

	// Below props cannot be shared with web, since there are no modalLinks and we have a different router setup.
	let _onClick
	if (extLink) _onClick = () => window.open(extLink, '_blank')
	if (to)
		_onClick = () => {
			navigate(to)
			window.scrollTo(0, 0)
		}
	if (onClick) _onClick = onClick

	return (
		<LayoutComponent
			ref={forwardRef}
			onClick={_onClick}
			width={width}
			height={height}
			maxWidth={maxWidth}
			gap={gap}
			margin={margin}
			{...rest}
		/>
	)
})

const getResponsiveValue = value => {
	const {mobile, tablet} = useWindowWidth()

	if (_.isArray(value)) {
		if (mobile) {
			// Mobile: the first value in the array is always for mobile
			return value[0]
		} else if (tablet) {
			// Tablet: The second value of the array is always for tablet
			return value[1]
		} else {
			// Desktop: It will take the last value of the array (either 2 or 3)
			return value[value.length - 1]
		}
	}
	return value
}

export const getResp = getResponsiveValue

const LayoutComponent = forwardRef((props, forwardedRef) => {
	let {
		children,
		childrenEqual,
		childrenGrow,
		type,
		name,
		className,
		gap = 4,
		margin,
		padding,
		height,
		width,
		to,
		extLink,
		onClick = _.noop,
		direction = 'horizontal',
		debug,
		baseColor = 'blue',
		style = {},
		active,
		absolute,
		fixed,
		sticky,
		childrenPerRow,
		maxHeight,
		maxWidth,
		minWidth,
		minHeight,
		initialBackground = 'initial',
		horizontal,
		sizePx,
		block,
		relative,
		disabled,
		parentGap,
		colorSet = !!props.initialBackground,
		borderRadius,
		wrap = !!childrenPerRow,
		noTextSelect,
		tooltip,
		...rest
	} = props
	const {t: parseT} = useI18nContext()
	const localRef = useRef()
	const mergedRef = useMergedRefs(localRef, forwardedRef)

	const content = childrenWithClonedProps({
		children,
		props: {...(name && {name}), parentGap: gap},
	})

	const cssWidth = validCssSize(width) || validCssSize(sizePx)
	const cssHeight = validCssSize(height) || validCssSize(sizePx)

	const styleProps = {
		...props,
		width,
		height,
		cssWidth,
		cssHeight,
		direction,
		wrap,
	}
	const directionStyles = direction === 'vertical' ? verticalStyles(styleProps) : horizontalStyles(styleProps)

	let colorSetStateColors = {}
	if (colorSet) {
		const computedStyles = localRef?.current && getComputedStyle(localRef?.current)
		const {borderColor, color, backgroundColor} = computedStyles || {}
		colorSetStateColors = formatCssVars({
			'set-border-color': borderColor,
			'set-content-color': color,
			'set-background-color': backgroundColor,
		})
	}

	const styles = {
		...colorSetStateColors,
		...directionStyles,
		maxHeight,
		maxWidth,
		minWidth,
		minHeight,
		overflowY: maxHeight && 'auto',
		overflowX: maxHeight && 'hidden',
		gap: gap + 'px',
		position: relative && 'relative',
		...getBorderRadius(borderRadius),
		...getSpacing(margin, 'margin'),
		...getSpacing(padding, 'padding'),
		...getPosition(absolute, 'absolute'),
		...getPosition(fixed, 'fixed'),
		...getPosition(sticky, 'sticky'),
		...(childrenPerRow && formatCssVars({'children-per-row': childrenPerRow})),
		...(childrenEqual && formatCssVars({'children-amount': children.length})),
		...formatCssVars({'initial-background': initialBackground}),
		...colorVariables(baseColor),
		'--gap': gap + 'px',
		'--parent-gap': parentGap + 'px',
		display: block && 'block',
		...style,
	}

	// We check if the parent has a hover, in that case the has-hover class is passed down to its child layout
	// This is in the case where the child has the colorSet logic, but the parent handles the hover or click logic
	const parentClickClassNames = ['eitje-layout-has-hover', 'link']
	const hasParentHover = parentClickClassNames.some(c => localRef?.current?.parentNode?.className.includes(c))
	const isDisabled = props.colorSet == 'disabled' || props.disabled
	const isInteractive = !!props.onClick || !!props.hasHover || hasParentHover || !!to || !!extLink
	const hasHover = !isDisabled && isInteractive

	if (disabled) colorSet = 'disabled'

	const classNames = utils.makeCns(
		utils.makeCnVariants(
			'eitje-layout',
			childrenEqual && 'children-equal',
			childrenGrow && 'children-grow',
			childrenPerRow && 'children-per-row',
			type === 'card' && 'card',
			props.grow && 'grow',
			colorSet && 'color-set',
			colorSet,
			hasHover && 'has-hover',
			debug && 'debug',
			horizontal === 'last-item-end' && 'last-item-end',
			noTextSelect && 'no-text-select',
			...borderStyles(rest),
		),
		active && 'active',
		className,
	)

	const popoutTitle = parseT(disabled || tooltip)
	const Wrapper = popoutTitle && _.isString(popoutTitle) ? PopoutCard : Fragment
	const wrapperProps = popoutTitle && {displayContents: true, title: popoutTitle}

	return (
		<Wrapper {...wrapperProps}>
			<div ref={mergedRef} className={classNames} onClick={onClick} {...rest} style={styles}>
				{content}
			</div>
		</Wrapper>
	)
})
