import {useEffect, useRef, useState} from 'react'
import utils from '@eitje/web_utils'
import _ from 'lodash'
import {withColor} from '../../../helpers/colors'
import {InfoPopout} from './info_popout'
import {fontWeights} from './constants'
import {Layout} from '../../layout'
import {TruncateTooltip} from './truncate_tooltip'
import './styles/index.less'
import {useI18nContext} from '/src/contexts'

const BaseText = props => {
	let {
		children,
		className,
		t = {},
		bold,
		semiBold,
		truncate,
		underline,
		italic,
		strikeThrough,
		popoutTitle,
		popoutBody,
		color,
		onClick,
		center,
		weight,
		bodyLayoutProps = {},
		noTextSelect,
		fontSize,
		truncateTooltip = true,
		HtmlTag = 'p',
		lineHeight,
		fontFamily = 'system-ui',
		countSuffix,
		styles,
		baseColor, // only meant to forward to TruncateTooltip's Layout
	} = props
	const {t: parseT} = useI18nContext()
	const textRef = useRef()
	const [isTruncated, setIsTruncated] = useState()

	const translated = parseT(t)
	const text = utils.exists(children) ? children : translated
	const textString = countSuffix && _.isString(text) ? `${text} (${countSuffix})` : text

	useEffect(() => {
		setIsTruncated(textRef?.current?.scrollWidth > textRef?.current?.offsetWidth)
	}, [textString])

	if (!utils.exists(textString)) return null

	const classNames = utils.makeCns(
		className,
		bold && !weight && 'bold',
		semiBold && !weight && 'semi-bold',
		truncate && 'truncate',
		underline && 'underline',
		italic && 'italic',
		strikeThrough && 'strike-through',
		center && 'text-center',
		onClick && 'pointer',
		noTextSelect && 'no-text-select',
		fontFamily === 'system-ui' ? 'system-ui' : 'raleway',
	)

	const _styles = {
		maxWidth: _.isNumber(truncate) && truncate + 'px',
		color,
		fontWeight: fontWeights[weight],
		fontSize,
		lineHeight,
		...styles,
	}

	const textElement = (
		<HtmlTag ref={textRef} className={classNames} style={_styles} onClick={onClick} data-heap-id={props['data-heap-id']}>
			{textString}
		</HtmlTag>
	)
	const showPopout = popoutTitle || popoutBody || props.PopoutComponent
	const showTruncationTooltip = isTruncated && truncateTooltip
	const popoutBodyElement = <Layout {...bodyLayoutProps}>{popoutBody}</Layout>

	if (showPopout)
		return (
			<InfoPopout
				textString={textString}
				textElement={textElement}
				title={popoutTitle}
				body={popoutBody && popoutBodyElement}
				showTruncationTooltip={showTruncationTooltip}
				{...props}
			/>
		)
	if (showTruncationTooltip) return <TruncateTooltip textElement={textElement} textString={textString} baseColor={baseColor} />
	return textElement
}

const getSuffix = ({large, small, mini, size}) => (large ? 'large' : small ? 'small' : mini ? 'mini' : size || 'regular')

export const Title = withColor(({className, bold = true, ...rest}) => {
	const suffix = getSuffix(rest)
	const classNames = utils.makeCns('eitje-title', `eitje-title-${suffix}`, className)
	return <BaseText className={classNames} bold={bold} {...rest} />
})

export const Text = withColor(({className, ...rest}) => {
	const suffix = getSuffix(rest)
	const classNames = utils.makeCns('eitje-text', `eitje-text-${suffix}`, className)
	return <BaseText className={classNames} {...rest} />
})
