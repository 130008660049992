import utils from '@eitje/utils'
import {Colors} from '/src/helpers'
import {useWindowWidth} from '/src/contexts'
import {Layout, getResp} from '../layout'
import {Text, Title} from '../text'
import {HORIZONTAL_BROWSER_MARGIN, Label, ProductHighlightChildren} from './components'
import {Blob} from '../blob'
import {FeatureSnippets} from './components/feature_snippets'
import './styles.less'

const inlineStyles = {justifyContent: 'center'}

export const ProductHighlight = props => {
	const {
		name,
		highlights,
		shortHighlights,
		horizontalInversed,
		marginTop,
		blobColor = 'red',
		blobNumber = 1,
		showTitleOnMobile = true,
		mobilePreview,
		uuid,
		paragraph,
		buttonText,
		label,
		...rest
	} = props
	const {mobile, tablet, desktop} = useWindowWidth()
	const isInversed = horizontalInversed || !desktop
	const elements = desktop ? highlights : shortHighlights

	const inlineStylesText = {
		maxWidth: getResp(['100%', `calc(100% - ${HORIZONTAL_BROWSER_MARGIN * 2}px)`]),
	}

	// The mobile app preview with a single asset is different than "other" app previews in multiple ways
	// "other" meaning: desktop previews or app previews with 2 assets
	// we have this variable to handle these cases.
	const mobilePreviewTablet = tablet && mobilePreview?.length === 1
	const isHorizontal = mobilePreviewTablet || desktop || (tablet && !!props.asset)
	const isVertical = !isHorizontal

	const labelColor = Colors[`midtone${utils.capitalize(blobColor)}`]
	const _title = <Title center={isVertical} small={isHorizontal} t={name} />
	const _label = label && <Label color={labelColor} label={label} />

	const highlightGap = [16, mobilePreviewTablet ? 40 : 24, props.asset ? 100 : mobilePreview ? 0 : 40]

	return (
		<>
			<Layout
				id={uuid}
				className="product-highlight"
				relative
				marginTop={marginTop}
				width="100%"
				horizontal="center"
				style={inlineStyles}
				direction={isVertical && 'vertical'}
				gap={highlightGap}
			>
				{desktop && !props.asset && (
					<Blob
						number={blobNumber}
						color={blobColor}
						horizontalBlobOffset={mobilePreview ? -60 : -150}
						position={horizontalInversed ? 'left' : 'right'}
						top={120}
					/>
				)}
				{isVertical && (
					<Layout direction="vertical" gap={[4, 0]} horizontal="center">
						{!mobile && _label}
						{showTitleOnMobile && _title}
						{paragraph && <Text styles={inlineStylesText} fontSize={tablet && 18} center t={paragraph} />}
					</Layout>
				)}
				<ProductHighlightChildren
					isInversed={isInversed}
					elements={elements}
					mobilePreview={mobilePreview}
					isHorizontal={isHorizontal}
					desktop={desktop}
					paragraph={paragraph}
					_title={_title}
					_label={_label}
					buttonText={buttonText}
					{...rest}
				/>
			</Layout>
			<FeatureSnippets {...props} />
		</>
	)
}
