import {Icon} from '../icon'
import utils from '@eitje/utils'
import {Layout} from '../layout'
import {Text} from '../text'
import {Link} from '../link'
import {useWindowWidth} from '/src/contexts'
import './styles.less'
import {Fragment} from 'react'

const iconOffset = '44px'

export const VacancyPreview = ({name, salaryRange, slug, popoutTitle, popoutBody}) => {
	const {mobile} = useWindowWidth()
	const classNames = utils.makeCnVariants('vacancy-preview', slug && 'with-link')
	const Wrapper = slug ? Link : Fragment

	// TMP: check build error
	const wrapperProps = slug ? {displayContents: true} : {}
	return (
		<Wrapper to={`/vacatures/${slug}`} {...wrapperProps}>
			<Layout
				className={classNames}
				padding="16 12"
				gap={20}
				borderTop
				borderBottom={!slug} // the one without the slug it the open application, which is the last one
				horizontal="spaceBetween"
				width="100%"
			>
				<Layout direction={mobile ? 'vertical' : 'horizontal'} width={`calc(100% - ${iconOffset})`}>
					<Layout width={mobile ? '100%' : '50%'}>
						<Text
							bold
							t={name}
							popoutTitle={popoutTitle}
							popoutBody={popoutBody}
							mailLink="mailto:jobs@eitje.app"
							contact={!!popoutTitle}
						/>
					</Layout>
					<Layout width={mobile ? '100%' : '25%'}>
						<Text t={salaryRange} />
					</Layout>
					<Layout width={mobile ? '100%' : '25%'}>
						<Text t="common.in_amsterdam" />
					</Layout>
				</Layout>
				{slug && <Icon name="arrow-right" size={mobile ? 16 : 24} />}
			</Layout>
		</Wrapper>
	)
}
