import {graphql} from 'gatsby'
import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {formatData, withFlattenedResponse} from '/src/helpers'
import {Helmet} from 'react-helmet'
import {Banner, PageLayout} from '/src/components'

const Page = ({data}) => {
	const {seo} = formatData({data})
	return (
		<>
			<Helmet>
				<link rel="canonical" href="" />
			</Helmet>
			<PageLayout name="404" hideFooter {...seo}>
				<Banner type="404" />
			</PageLayout>
		</>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "404"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}
	}
`

export default withFlattenedResponse(Page)
