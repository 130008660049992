import {useEffect, useState} from 'react'
import {useEventListener} from '../../../hooks/use_event_listener'

export const useReferenceElement = ({referenceElement, showPopout}) => {
	const hasDocument = typeof document !== 'undefined'
	const scrollParent = hasDocument && document.querySelector('.page-body')

	const [virtualElement, setVirtualElement] = useState({
		getBoundingClientRect: () => {},
	})

	const handleSetRef = () => {
		if (showPopout) {
			setVirtualElement({
				getBoundingClientRect: () => referenceElement?.firstElementChild?.getBoundingClientRect(),
			})
		}
	}

	useEffect(() => {
		if (referenceElement && showPopout) {
			handleSetRef()
		}
	}, [referenceElement, showPopout])

	useEventListener('scroll', handleSetRef, scrollParent)

	return virtualElement
}
