import {useState} from 'react'
import {Colors} from '/src/helpers'
import {Layout, getResp} from '../layout'
import {Text, Title} from '../text'
import {formatCurrency} from '../../helpers/format_currency'
import {calculateAmountPerMonth, getDiscountAmount, getFutureDiscount} from './helpers'
import {DiscountOverview} from './discount_overview'
import {DiscountInputs} from './discount_inputs'
import {CallButton, TryEitjeButton} from '../eitje_button/buttons'
import {useWindowWidth} from '/src/contexts'
import {InputLayout} from './input_layout'

export const CostCalculator = ({...rest}) => {
	const [activeTeamMembers, setActiveTeamMembers] = useState(10)
	const [packageType, setPackageType] = useState('planning')
	const [charityStatus, setCharitystatus] = useState(false)
	const [tierDiscount, setTierDiscount] = useState(false)
	const {tablet} = useWindowWidth()
	const inlineStyles = {borderRadius: 12, background: Colors.white}

	let currentDiscount = getDiscountAmount(activeTeamMembers)
	let {futureDiscount, futureDiscountAmount} = getFutureDiscount(activeTeamMembers, tierDiscount)
	if (charityStatus) {
		currentDiscount = 0
		futureDiscount = 0
	}

	const amountPerMonth = calculateAmountPerMonth({
		currentDiscount,
		charityStatus,
		activeTeamMembers,
		packageType,
	})

	const amountPerMonthWithoutDiscount = calculateAmountPerMonth({
		discount: false,
		currentDiscount,
		charityStatus,
		activeTeamMembers,
		packageType,
	})

	const componentProps = {
		currentDiscount,
		futureDiscount,
		futureDiscountAmount,
		packageType,
		setPackageType,
		activeTeamMembers,
		setActiveTeamMembers,
		charityStatus,
		setCharitystatus,
		tierDiscount,
		setTierDiscount,
	}

	const showPriceWithoutDiscount = amountPerMonth < amountPerMonthWithoutDiscount

	return (
		<Layout
			className="cost-calculator"
			border
			padding={getResp(['20 16', '42 60'])}
			width={['100%', 595]}
			gap={[16, 32]}
			direction="vertical"
			style={inlineStyles}
			{...rest}
		>
			<Layout horizontal="center" borderBottom padding={getResp(['- - 12 -', '- - 28 -'])}>
				<Title t="cost_calculator.calculate_costs" />
			</Layout>
			<DiscountOverview {...componentProps} />
			<DiscountInputs {...componentProps} />
			<Layout padding={tablet && '- - 28 -'} direction="vertical" width="100%" gap={12}>
				{showPriceWithoutDiscount && (
					<InputLayout>
						<Text darkGrey large t="cost_calculator.without_discount" />
						<Title strikeThrough mini darkGrey semiBold>
							{formatCurrency(amountPerMonthWithoutDiscount)}
						</Title>
					</InputLayout>
				)}
				<InputLayout>
					<Text large bold t="cost_calculator.cost_per_month" />
					<Title>{formatCurrency(amountPerMonth)}</Title>
				</InputLayout>
				<Text darkGrey t="cost_calculator.ex_vat" />
			</Layout>
			<Layout horizontal="center" width="100%">
				<TryEitjeButton width="50%" />
				<CallButton iconLeft={null} width="50%" t="common.call_with_sales" colorSet="color-bordered-white" />
			</Layout>
		</Layout>
	)
}
