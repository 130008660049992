import {AnimatedIcon} from '../animated_icon'
import {formatCurrency} from '../../helpers/format_currency'
import {Layout} from '../layout'
import {Text} from '../text'
import {useI18n} from '/src/i18n'
import './styles.less'

export const DiscountOverview = ({
	currentDiscount,
	futureDiscount,
	charityStatus,
	futureDiscountAmount,
	tierDiscount,
	activeTeamMembers,
}) => {
	const [t] = useI18n()

	if (!tierDiscount && !charityStatus && !(currentDiscount > 0) && !(futureDiscount > 0)) return null

	const discountTooltipProps = {
		chat: true,
		popoutTitle: 'popouts.volume_discount.title',
		popoutBody: t('popouts.volume_discount.text'),
		center: true,
	}

	return (
		<Layout className="discount-overview" horizontal="center" borderBottom padding="- 20 28 20" direction="vertical" width="100%" gap={8}>
			<AnimatedIcon animation="receipt" size={40} />
			<Text large bold t="cost_calculator.your_discount" />
			<Layout width="100%" direction="vertical" horizontal="center">
				{currentDiscount > 0 && (
					<Text {...discountTooltipProps}>
						{t('cost_calculator.discount_per_member', {
							amount: formatCurrency(currentDiscount),
						})}
					</Text>
				)}
				{((tierDiscount && activeTeamMembers < 751 && !charityStatus) || futureDiscount > 0) && (
					<Text {...discountTooltipProps}>
						{t('cost_calculator.future_discount', {
							memberAmount: futureDiscountAmount,
							discountAmount: formatCurrency(futureDiscount),
						})}
					</Text>
				)}
				{charityStatus && (
					<Text chat popoutTitle="popouts.anbi.title" popoutBody={t('popouts.anbi.text')} t="cost_calculator.50_percent_discount" />
				)}
			</Layout>
		</Layout>
	)
}
