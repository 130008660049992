import {PopoutCard} from '..'
import {Children, forwardRef} from 'react'
import {childrenWithClonedProps} from '../../../helpers/children'
import {Text} from '../../text/base'

const footerLayoutProps = {
	direction: 'vertical',
}

export const BasicPopout = forwardRef(({children, buttons, text, height, title, ...rest}, ref) => {
	const _buttons = childrenWithClonedProps({
		children: Children.toArray(buttons).filter(Boolean),
		props: {height, width: 'full'},
	})

	// When we only render the footer, we dont want to use the default width
	// instead we then use the size of the content
	const width = !text && !title ? null : 250

	return (
		<PopoutCard
			maxHeight={400}
			width={width}
			ref={ref}
			title={title}
			noBorders
			body={<Text t={text} />}
			footer={_buttons}
			footerLayoutProps={footerLayoutProps}
			{...rest}
		>
			{children}
		</PopoutCard>
	)
})
