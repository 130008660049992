import {Icon} from '../icon'
import './styles.less'

const checkboxNameMapping = {
	unchecked: 'checkbox-unchecked',
	checked: 'checkbox-checked-colored',
}

export const Checkbox = ({checked, onClick, size = 16}) => {
	const status = checked ? 'checked' : 'unchecked'

	return (
		<div className="checkbox-container" onClick={onClick}>
			<Icon name={checkboxNameMapping[status]} size={size} />
		</div>
	)
}
