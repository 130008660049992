import {Icon} from '../icon'
import {Layout} from '../layout'
import {Link} from '../link'
import {Text} from '../text'
import {Fragment} from 'react'
import {useSimpleContext} from '../../contexts/simple'
import {isCurrentPage, isSubItemActive} from './helpers'

export const MenuLink = ({link, title, onClick, padding, width, ...rest}) => {
	if (!link && !onClick) return null
	const isActive = rest.isActive || isCurrentPage(link)
	return (
		<Link noInitialStyles to={link} style={{padding, width}}>
			<Text mediumBlue={isActive} t={title} fontFamily="system-ui" {...rest} />
		</Link>
	)
}

export const NestedMobileItem = ({item, setIsOpen, isOpen}) => {
	let condOpts = {}
	if (item.items?.length) {
		condOpts.onClick = () => setIsOpen(!isOpen)
	}

	const isActive = isSubItemActive(item.items)

	return (
		<>
			<Layout {...condOpts} padding={16} horizontal="spaceBetween">
				<Text fontFamily="system-ui" mediumBlue={isActive} weight="bold" t={item.title} fontSize={18} />
				{item.items && <Icon name={`caret-${isOpen ? 'up' : 'down'}`} />}
			</Layout>
			{isOpen && (
				<Layout padding="- 28 16 28" direction="vertical" gap={20}>
					{item.items.filter(Boolean).map(SubItem)}
				</Layout>
			)}
		</>
	)
}

export const MobileItem = ({item}) => <MenuLink {...item} padding={16} width="100%" weight="bold" fontSize={18} />

const SubItem = ({link, title, children}) => {
	const Wrapper = children?.length & !link ? Fragment : Link
	const isActive = isCurrentPage(link)
	const {setMobileMenuOpen} = useSimpleContext()

	return (
		<Layout direction="vertical" onClick={() => setMobileMenuOpen(false)} gap={16}>
			<Wrapper to={link}>
				<Text mediumBlue={isActive} fontSize={16} fontFamily="system-ui" t={title} weight="medium" />
				{children && (
					<Layout direction="vertical" gap={18} borderLeft margin="16 - - 4" padding="- - - 8">
						{children.map(child => (
							<MenuLink {...child} fontSize={14} uppercase darkGrey />
						))}
					</Layout>
				)}
			</Wrapper>
		</Layout>
	)
}
