import {useRef} from 'react'
import utils from '@eitje/utils'
import {PopoutCard} from '../popout_card'
import {BaseEitjeButton} from '../eitje_button/base'
import {Layout} from '../layout'
import {Text} from '../text'

import './styles.less'

const PADDING = [8, 12]
const LIST_PICKER_ITEM_HEIGHT = 40

const ListPickerItem = ({item, isSelected, onSelect}) => {
	const itemClassNames = utils.makeCns('list-picker-item', isSelected && 'active')

	return (
		<Layout
			className={itemClassNames}
			gap={8}
			padding={PADDING}
			vertical="center"
			width="full"
			colorSet
			hasHover
			height={LIST_PICKER_ITEM_HEIGHT}
			onClick={onSelect}
		>
			<Text className="list-picker-item-text" truncate truncateTooltip={false} noTextSelect>
				{item.name}
			</Text>
		</Layout>
	)
}

export const ListPicker = ({value, onChange, items, placement = 'bottom-start', minWidth, maxHeight = 400}) => {
	const containerRef = useRef(null)
	const hoverCardRef = useRef()

	const selectedItem = items.find(item => item.id === value)

	const handleSelect = item => {
		onChange(item.id)
		hoverCardRef?.current?.hide()
	}

	const body = (
		<Layout className="list-picker-regular-container" width="full" height="full" direction="vertical" gap={0}>
			{items.map(item => (
				<ListPickerItem key={item.id} item={item} isSelected={value === item.id} onSelect={() => handleSelect(item)} />
			))}
		</Layout>
	)

	return (
		<div className="list-picker-dropdown-container" ref={containerRef} tabIndex={0}>
			<PopoutCard
				className="list-picker"
				forceUpdateDependency={JSON.stringify(value)}
				ref={hoverCardRef}
				bodyPadding={0}
				footerLayoutProps={{padding: 6}}
				body={body}
				trigger="click"
				placement={placement}
				minWidth={minWidth}
				maxHeight={maxHeight}
			>
				<BaseEitjeButton horizontal="spaceBetween" height="regular" dropdown minWidth={minWidth}>
					{selectedItem.name}
				</BaseEitjeButton>
			</PopoutCard>
		</div>
	)
}
